import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import mitt from 'mitt'
import router from './router.js'
import * as Sentry from "@sentry/vue";
import VueApexCharts from "vue3-apexcharts";


const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://6ae3e815a22ec7feaa4f9529ca5b2129@o4507744218447872.ingest.us.sentry.io/4506356961116160",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/soled\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

const emitter = mitt()
app.config.globalProperties.emitter = emitter

app.use(router);
app.use(VueApexCharts);

app.mount('#app');
