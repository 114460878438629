<template>
  <header class="navbar navbar-expand-sm navbar-light d-print-none">
    <div class="container-xl">
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        <a href="/">
          <img src="../../assets/logo.png" alt="logo" class="navbar-brand-image">
        </a>
      </h1>

      <div class="navbar-nav flex-row">
        <!-- Search bar -->
        <div class="nav-item">
          <div class="input-icon">
            <input type="text" value="" class="form-control border-gray" placeholder="Search style ID…" title="Search" @input="searchInput($event)" @keyup.enter="searchEnter($event)" style="border-radius: 0 !important">
            <span class="input-icon-addon">
              <IconSearch />
            </span>
          </div>
        </div>
      </div>

      <div class="navbar-nav flex-row gap-4">
          <div class="nav-item">
            <div class="input-icon">
              <!-- Login button -->
              <button type="button" class="btn" v-if="!hasJwt" title="Login" @click="$router.push('/login')">
                <IconLogin />
                Login
              </button>

              <!-- Settings button
              <button type="button" class="btn" title="Settings" v-else @click="$router.push('/settings')">
                <IconSettings />
              </button> -->
            </div>
          </div>

          <div class="nav-item">
            <div class="input-icon">
              <!-- Register button -->
              <button type="button" class="btn" v-if="!hasJwt" title="Register" @click="$router.push('/register')">
                <IconLogin />
                Register
              </button>

              <!-- Logout button -->
              <button type="button" class="btn" title="Logout" v-else @click="logout()">
                <IconLogout />
              </button>
            </div>
          </div>

        <div class="nav-item" v-if="hasJwt">
          <div class="input-icon">
            <button type="button" class="btn" title="Bulk Export" @click="$router.push('/export')">
              <IconTableExport />
            </button>
          </div>
        </div>

        <div class="nav-item" v-if="hasJwt">
          <div class="input-icon">
            <button type="button" class="btn" title="Orders" @click="$router.push('/orders')">
              <IconPackage />
            </button>
          </div>
        </div>

        <div class="nav-item" title="Cart" v-if="hasJwt">
          <CartComponent ref="cart"/>
        </div>

      </div>
    </div>
  </header>
</template>

<script>
import {IconLogin, IconLogout, IconSearch, IconSettings, IconShoppingCart, IconTableExport, IconPackage} from '@tabler/icons-vue';
import CartComponent from "./cart/CartComponent.vue";
import {ref} from "vue";
import axios from "axios";
import _ from "lodash";

export default {
  name: "NavBarComponent",
  components: {
    IconShoppingCart,
    CartComponent,
    IconSearch,
    IconLogin,
    IconSettings,
    IconLogout,
    IconTableExport,
    IconPackage,
  },
  data() {
    return {
      hasJwt: false,
      cart_component: ref('cart'),
    }
  },
  mounted() {
    if(navigator.userAgent.indexOf('iPhone') > -1 ) {  // iPhone, quick fix for viewport
        document
          .querySelector("[name=viewport]")
          .setAttribute("content","width=device-width, initial-scale=1, maximum-scale=1");
    }

    axios.interceptors.response.use(function(response) {
      return response;
    }, function(error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('jwt');
      }
        return Promise.reject(error);
      }
    );

    this.hasJwt = !!localStorage.getItem('jwt');
  },
  methods: {
    logout() {
      localStorage.removeItem('jwt');
      this.hasJwt = false;
      this.$router.push('/login');
    },

    searchInput: _.debounce(function (event) {
      let query = event.target.value;

      if (query.length > 0) {
        this.$router.push({
          name: 'search',
          query: {
            query: query,
          }
        });
      } else {
        this.$router.back();
      }
    }, 500),

    searchEnter(event) {
      let query = event.target.value;

      if (query.length > 0) {
        this.$router.push({
          name: 'search',
          query: {
            query: query,
          }
        });
      } else {
        this.$router.back();
      }
    }
  }
}
</script>

<style scoped>
</style>