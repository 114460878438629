<script setup>
import HistoryCardComponent from "../components/product/HistoryCardComponent.vue";
</script>


<template>
  <div class="container py-2">
    <div class="row row-deck row-cards">
      <div class="col-sm-3" v-for="product in history">
        <HistoryCardComponent
            :image_url=product.image_url
            :title=product.title
            @click="$router.push({ name: 'shoe', params: { slug: product.slug }})"
        />
      </div>
    </div>

    <div v-if="jwt === null">
      <div class="empty">
        <div class="empty-icon">
          <IconMoodSmile />
        </div>
        <p class="empty-title">You are not logged in!</p>
        <p class="empty-subtitle text-muted">
          You need to be logged in to see your history.
        </p>
      </div>
    </div>

    <div v-if="history.length === 0 && jwt !== null">
      <div class="empty">
        <div class="empty-icon">
          <IconMoodSmile />
        </div>
        <p class="empty-title">No history found.</p>
        <p class="empty-subtitle text-muted">
          You have not viewed any products yet.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { IconMoodSmile } from '@tabler/icons-vue';
import axios from 'axios';

export default {
  name: "HomeView",
  components: {
    IconMoodSmile
  },
  data() {
    return {
      history: [],
      jwt: null
    }
  },
  methods: {
  },
  mounted() {
    this.jwt = localStorage.getItem('jwt')

    if (localStorage.getItem('jwt') !== null) {
      axios.get(import.meta.env.VITE_BASE_API + import.meta.env.VITE_BASE_API_URL + '/shoe_history/').then(response => {
        this.history = response.data.shoes
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style>
body {
  background-color: #FFFFFF !important;
}
</style>