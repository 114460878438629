<template>
  <div class="card">
    <img class="card-img-top px-2" :src="image_url" alt="Sneaker image" v-if="image_url !== ''">
    <img class="card-img-top" src="https://via.placeholder.com/600x300?text=Image+not+found." alt="Sneaker image" v-else>
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryCardComponent",
  props: {
    image_url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    }
  },
}
</script>

<style scoped>
.card{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
}

.card:hover{
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
</style>