<script>
import {IconPackageImport} from "@tabler/icons-vue";
import axios from "axios";

export default {
  name: "BulkImportComponent",
  components: {IconPackageImport},
  data() {
    return {
      loading: false,
      message: undefined,
      success: undefined
    }
  },
  methods: {
    submitFile(event) {
      this.loading = true;
      let file = event.target.files[0];

      let formData = new FormData();
      formData.append('file', file);

      axios.post(import.meta.env.VITE_BASE_API + import.meta.env.VITE_BASE_API_URL + '/cart/bulk/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.loading = false;
        this.success = true;

        this.emitter.emit('set-cart', {
          cart: response.data.cart
        })
      }).catch(error => {
        this.loading = false;
        this.message = error.response ? error.response.data.message : "Failed to upload file.";
      });
    }
  }
}
</script>

<template>
<label for="cart-import-input" class="btn" v-if="!loading">
  <IconPackageImport />
  <input type="file" id="cart-import-input" class="visually-hidden" accept=".csv,.xlsx" @change="submitFile" />
</label>

<button class="btn" @click="submitFile" v-if="loading">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
</button>
</template>

<style scoped>

</style>