<script>
export default {
  name: "CartItem",
  props: {
    item: Object
  }
}
</script>

<template>
<td>
  <div class="d-flex align-items-center">
    <div class="flex-shrink-0">
      <img :src="item.image_url" class="rounded" width="49" height="35" alt="Shoe">
    </div>
    <div class="ms-3">
      <a :href="'/shoe/' + item.slug" target="_blank" class="text-body d-block">{{ item.name }}</a>
      <small>
        <span class="text-muted">Style ID: </span> {{ item.style_id }}
        <span class="text-muted">Size: </span> {{ item.size }}
      </small>
    </div>
  </div>
</td>
<td>
  <div class="d-flex">
    <div class="flex-grow-1">
      ${{ item.offer.toFixed(0).toString() }}
    </div>
  </div>
</td>
</template>

<style scoped>

</style>