<script>
import { RouterView } from "vue-router";
import NavBarComponent from "./components/NavBarComponent.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    NavBarComponent,
    RouterView,
  },
  mounted() {
    let token = localStorage.getItem('jwt');

    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }
  }
}
</script>

<template>
  <div class="page">
    <NavBarComponent
        v-if="!['login', 'register'].includes(this.$router.currentRoute.value.name)"
    ></NavBarComponent>

    <RouterView/>
  </div>
</template>
